/* eslint no-console: 0 */
require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");

import "../css/application.css";
import "controllers";

import Vue from "vue/dist/vue.esm";
import Autocomplete from "@trevoreyre/autocomplete-vue";
import Calculator from "../components/calculator.vue";
import Company from "../components/company.vue";
import Cost from "../components/cost.vue";
import Field from "../components/field.vue";
import SlideOver from "../components/slide_over.vue";
import SlideOverContent from "../components/slide_over_content.vue";
import Modal from "../components/modal.vue";

Vue.use(Autocomplete);
Vue.component("calculator", Calculator);
Vue.component("company", Company);
Vue.component("cost", Cost);
Vue.component("field", Field);
Vue.component("slide-over", SlideOver);
Vue.component("slide-over-content", SlideOverContent);
Vue.component("modal", Modal);

document.addEventListener("DOMContentLoaded", () => {
  const app = new Vue({
    el: '[data-behavior="vue"]'
  });
});
