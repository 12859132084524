<template>
  <div class="bg-white flex flex-col flex-1">
    <div class="bg-white px-6 pt-5 pb-3 border-b border-gray-200">
      <h4 class="font-semibold text-gray-700">Quick claims simulator</h4>
    </div>
    <div class="bg-white flex-1 px-6 py-6">
      <h5 class="font-semibold text-sm text-gray-700">
        Annual spend on R&D activities 
      </h5>
      <p class="font-medium text-xs text-gray-400">
        (This would spending on expenditure on staff, software and materials)
      </p>

      <currency-input
        v-model="expenditure"
        class="my-4 appearance-none rounded-none border-b border-pink px-2 py-1 font-semibold text-lg text-gray-800 focus:outline-none w-full" />

      <h5 class="font-semibold text-sm text-gray-700">
        Company financial status
      </h5>

      <div class="mt-3 flex items-center space-x-4">
        <div class="flex items-center">
          <input
            v-model="profitable"
            v-bind:value="false"
            id="loss-making"
            type="radio"
            class="form-radio cursor-pointer h-5 w-5 text-pink transition duration-150 ease-in-out" />
          <label for="loss-making" class="cursor-pointer ml-2">
            <span
              class="block text-xs leading-5 text-gray-700"
              v-bind:class="{ 'text-pink': !profitable }"
              >Loss making</span
            >
          </label>
        </div>

        <div class="flex items-center">
          <input
            v-model="profitable"
            v-bind:value="true"
            id="profit-making"
            type="radio"
            class="form-radio cursor-pointer h-5 w-5 text-pink transition duration-150 ease-in-out" />
          <label for="profit-making" class="cursor-pointer ml-2">
            <span
              class="block text-xs leading-5 text-gray-700"
              v-bind:class="{ 'text-pink': profitable }"
              >Profitable</span
            >
          </label>
        </div>
      </div>
    </div>
    <div class="bg-white pb-3 px-4 font-semibold text-center text-gray-700">
      <p class="text-sm leading-none">You could claim up to</p>
      <p class="text-3xl">{{ credit | toCurrency }}</p>
    </div>
  </div>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import VueCurrencyInput from "vue-currency-input";

const pluginOptions = {
  globalOptions: {
    locale: "en",
    currency: "GBP",
    valueAsInteger: false,
    valueRange: { min: 0 },
    precision: 0
  }
};
Vue.use(VueCurrencyInput, pluginOptions);

Vue.filter("toCurrency", function (value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  });
  return formatter.format(value);
});

export default {
  data: () => ({
    expenditure: 100000,
    profit: 40000,
    profitable: false,
    multiplier: 1.3,
    corpTaxRate: 0.19,
    taxCreditRate: 0.145
  }),
  computed: {
    credit() {
      if (this.profitable) {
        return this.expenditure * this.multiplier * this.corpTaxRate;
      } else {
        return (
          (this.expenditure + this.expenditure * this.multiplier) *
          this.taxCreditRate
        );
      }
    }
  }
};
</script>
