<template>
  <div>
    <button
      type="button"
      @click="isOpen = !isOpen"
      class="flex items-center justify-center absolute top-0 right-0 mt-5 mr-6 rounded-full bg-gray-200 h-10 w-10 focus:outline-none">
      <svg
        class="w-6 h-6 text-pink"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor">
        <path
          fill-rule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
          clip-rule="evenodd" />
      </svg>
    </button>

    <transition
      enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
      enter-class="translate-x-full"
      enter-to-class="translate-x-0"
      leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
      leave-class="translate-x-0"
      leave-to-class="translate-x-full">
      <div
        v-show="isOpen"
        class="fixed inset-0 overflow-hidden pointer-events-none z-50">
        <div class="absolute inset-0 overflow-hidden pointer-events-none">
          <section
            class="absolute inset-y-0 right-0 pl-10 max-w-full flex pointer-events-auto">
            <div class="w-screen max-w-sm">
              <div
                class="h-full flex flex-col space-y-6 py-6 bg-white shadow-xl overflow-y-scroll">
                <header class="px-4 sm:px-6">
                  <div class="flex items-start justify-between space-x-3">
                    <h2 class="leading-7 font-medium text-gray-900">
                      Guidance
                    </h2>
                    <div class="h-7 flex items-center">
                      <button
                        @click.prevent="isOpen = false"
                        aria-label="Close panel"
                        class="text-gray-400 hover:text-gray-500 focus:outline-none transition ease-in-out duration-150">
                        <svg
                          class="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor">
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </header>
                <div class="relative flex-1 px-4 sm:px-6">
                  <div class="absolute inset-0 px-4 sm:px-6">
                    <slot name="content"></slot>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data: () => ({
    isOpen: true
  })
};
</script>
