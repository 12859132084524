import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["links", "template", "sub"];

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "nested-fields";
    this.updateButtonVisibility();
  }

  add_association(event) {
    event.preventDefault();

    var content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime()
    );
    this.linksTarget.insertAdjacentHTML("beforebegin", content);

    this.updateButtonVisibility();
  }

  remove_association(event) {
    event.preventDefault();

    let wrapper = event.target.closest("." + this.wrapperClass);

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove();

      // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1;
      wrapper.style.display = "none";
    }

    this.updateButtonVisibility();
  }

  updateButtonVisibility() {
    if (this.limit && this.subTargets.length >= this.limit) {
      this.linksTarget.classList.add("hidden");
    } else {
      this.linksTarget.classList.remove("hidden");
    }
  }

  get limit() {
    if (this.data.has("limit")) {
      return parseInt(this.data.get("limit"));
    } else {
      return false;
    }
  }
}
