<template></template>

<script>
export default {
  props: ["id", "type"],
  methods: {
    open() {
      this.$root.$emit("active-cost", this.id, this.type);
    }
  }
};
</script>
