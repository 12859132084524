<template>
  <div>
    <button
      @click.prevent="isOpen = true"
      class="appearance-none focus:outline-none">
      <slot name="button"></slot>
    </button>

    <transition
      enter-active-class="ease-out duration-300"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="ease-in duration-200"
      leave-class="opacity-100"
      leave-to-class="opacity-0">
      <div v-show="isOpen" class="fixed z-50 inset-0 overflow-y-auto">
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-black opacity-75"></div>
          </div>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
          >&#8203;

          <transition
            appear
            enter-active-class="ease-out duration-300"
            enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to-class="opacity-100 translate-y-0 sm:scale-100"
            leave-active-class="ease-in duration-200"
            leave-class="opacity-100 translate-y-0 sm:scale-100"
            leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div
              v-show="isOpen"
              class="inline-block align-bottom bg-white rounded-lg px-4 sm:px-6 py-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline">
              <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  @click.prevent="isOpen = false"
                  type="button"
                  class="text-gray-500 hover:text-gray-400 focus:outline-none focus:text-gray-400 transition ease-in-out duration-150"
                  aria-label="Close">
                  <svg
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <div>
                <h3
                  class="text-base leading-6 font-medium text-gray-600"
                  id="modal-headline">
                  <slot name="title"></slot>
                </h3>
                <div class="mt-2 text-base">
                  <slot name="body"></slot>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["open"],
  data: () => ({
    isOpen: false
  }),
  created() {
    this.isOpen = this.open == "true";
  }
};
</script>
