<template>
  <div>
    <div class="relative">
      <autocomplete
        ref="autocomplete"
        name="company[name]"
        placeholder="Search"
        aria-label="Search"
        :search="search"
        :get-result-value="getResultValue"
        :default-value="name"
        @submit="handleSubmit"
        @blur="handleBlur"></autocomplete>

      <div class="absolute inset-y-0 right-0 pr-3 flex items-center">
        <a
          href="#"
          data-controller="tippy"
          data-text="Your registered company name should be the one specified in Companies House">
          <!-- Heroicon name: solid/question-mark-circle -->
          <svg
            class="cursor-pointer h-5 w-5 text-gray-300"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true">
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
              clip-rule="evenodd" />
          </svg>
        </a>
      </div>
    </div>

    <input type="hidden" name="company[company_number]" v-model="number" />
  </div>
</template>

<script>
export default {
  props: ["name", "number"],
  methods: {
    search(input) {
      this.number = null;

      const url = `https://api.company-information.service.gov.uk/search/companies/?q=${encodeURI(
        input
      )}`;

      return new Promise((resolve) => {
        if (input.length < 3) {
          return resolve([]);
        }

        fetch(url, {
          headers: new Headers({
            Authorization: `Basic NjMwYWFhOGUtMGE1NC00NDVkLTkxNzItNWM1NWM2NTEzMGUx`
          })
        })
          .then((response) => response.json())
          .then((data) => {
            resolve(data.items);
          });
      });
    },

    getResultValue(result) {
      return result.title;
    },

    handleSubmit(result) {
      this.number = result.company_number;
    },

    handleBlur(event) {
      if (!this.number) {
        this.$refs.autocomplete.value = "";
      }
    }
  }
};
</script>
