import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["icon", "panel"];

  toggle() {
    this.iconTarget.classList.toggle("-rotate-180");
    this.panelTarget.classList.toggle("hidden");
  }
}
