<template>
  <div class="mb-4 bg-white rounded-lg border border-gray-200 px-4 py-3">
    <div @click.prevent="open">
      <slot name="label"></slot>
    </div>
    <div v-show="isOpen">
      <slot name="input"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id", "type"],
  data: () => ({
    isOpen: false
  }),
  created() {
    this.$root.$on("active-question", this.activate);
  },
  methods: {
    open() {
      this.$root.$emit("active-question", this.id, this.type);
    },
    activate(id, type) {
      this.isOpen = this.id === id;
    }
  }
};
</script>
